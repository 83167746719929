import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/plugins/vant"; //按需引入vant
import "vant/lib/index.less"; // vant样式
import "../src/utils/global";
// import api from '../src/http/api' //引入相关的api请求接口
import Meta from "vue-meta";
import "../src/style/index.css"; //页面样式
import "../src/style/reset.css"; //重置样式以及修改vant的默认样式
import "../src/utils/fontsizerem.js"; //页面自适应js
import { ysfLogin } from "../src/http/modules/login";
import { getMyInfo } from "../src/http/modules/person";
import { Dialog } from "vant";
// 组件实例的原型的原型指向的是Vue.prototype
//任意组件可以使用API相关的接口
Vue.use(Meta);
// Vue.prototype.$api = api
Vue.config.productionTip = false;

function initialize() {
  console.log("应用启动时调用一次");
  //修改成云闪付登录
  upsdk.pluginReady(() => {
    //云闪付一键登录
    upsdk.appletAuth({
      success: function (d) {
        //  data ={code:’xxx’}
        console.log("code:" + d.code);
        let code = d.code;
        // 通过code获取用户信息
        let data = {
          code: code,
        };
        ysfLogin(data).then((res) => {
          console.log("登录返回的信息", res);
          if (res.code == 0) {
            console.log("登录成功");
            console.log("记得获取个人信息");
            // console.log("需要存储的token", res.detail.token);
            const userInfoString = JSON.stringify(res.detail.userInfo);
            upsdk.saveData({
              key: "token",
              value: res.detail.token,
              success: function (result) {
                // result = {code:'',msg:''}
                console.log("存储token结果", res.detail.token);
              },
            });
            upsdk.saveData({
              key: "userInfo",
              value: userInfoString,
              success: function (result) {
                // result = {code:'',msg:''}
              },
            });
            window.token = res.detail.token;
            // 获取个人信息
            getMyInfo({}).then((arr) => {
              console.log("获取个人信息", arr);
              if (arr.code == 0) {
                if (
                  !arr.detail.studentId ||
                  arr.detail.studentId === null ||
                  arr.detail.studentId === ""
                ) {
                  Dialog.alert({
                    title: "提示",
                    message:
                      "您需要先完善个人资料才能继续访问该页面。请到修改资料页面绑定信息",
                  }).then(() => {
                    // 用户点击确定
                    router.push("/modifyData");
                  });
                } else {
                  console.log("有学号");
                }
              }
            });
          } else {
            console.log("登录失败");
            Dialog.alert({
              title: "提示",
              message:
                "登录失败，请重新进入",
            }).then(() => {
              // 用户点击确定
              // window.location.reload(true);
            });
          }
        });
      },

      fail: function (error) {
        // error={errcode:’xxx’, errmsg:’xxx’}
      },
    });
  });
}

initialize(); // 只调用一次

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
