import axios from '../axios'
import utils from '../util'


// 包装器函数，用于处理自定义参数逻辑
const withCustomParams = (requestFunction) => {
    return (params) => {
        const signObject = utils.mySignObject(params);
        const { random, timeStamp, signature } = signObject;
        const newParams = {
            ...params,
            random,
            timeStamp,
            signature
        };
        return requestFunction(newParams);
    };
};

// 获取短信验证的token
export const getVerifyToken = withCustomParams((params) => {
    return axios({
        url: '/H5/GetValidateAntiforgeryToken',
        method: 'get',
        params
    });
});
// 获取短信验证码
export const getSMSCod = withCustomParams((params) => {
    return axios({
        url: '/H5/GetSMSCode',
        method: 'get',
        params
    });
});

// 用户登录
export const login = withCustomParams((params) => {
    return axios({
        url: '/H5/DoLogin',
        method: 'get',
        params
    });
});

//云闪付一键登录
export const ysfLogin = withCustomParams((params) => {
    return axios({
        url: '/UnionPayApp/UserAuthcodeLogin',
        method: 'get',
        params
    });
});








