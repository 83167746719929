
//文件下放入你所需用到的组件即可
import Vue from 'vue';
import Vant from 'vant';
// import 'vant/lib/button/style';
// import 'vant/lib/index.less';
import { NavBar, Toast, Button, Form, Field, Tabbar, TabbarItem, RadioGroup, Radio, Picker, Uploader, Popup, Tab, Tabs, Icon, Cell, CellGroup, Checkbox, CheckboxGroup, } from 'vant'
Vue.use(Button)
Vue.use(Form);
Vue.use(Field);
Vue.use(Tabbar).use(TabbarItem);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Uploader);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(Popup);
Vue.use(CellGroup);
Vue.use(Picker);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Toast);
Vue.use(NavBar);
