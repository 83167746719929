//  自适应
function fontRem() {
    var designW = 375;
    var html = document.getElementsByTagName('html')[0];
    var winW = html.offsetWidth;
    html.style.fontSize = Math.min((winW / designW) * 100) + 'px';
}

fontRem();
window.onresize = fontRem;
