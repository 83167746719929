import axios from "axios";
import config from "./config";
import utils from "./util";
import { Toast } from "vant";
const baseURL = utils.SERVER; // 'https://yourapi.com' // 自己后台API地址
const getUrl = (url) => {
  if (url.indexOf("://") == -1) {
    url = baseURL + url;
  }
  return url;
};
const getHeader = () => {
  try {
    var token = window.token;
    // console.log("axios封装是否有token", token);
    if (token) {
      return {
        Authorization: "Bearer " + " " + token,
        "Content-Type": "application/json;charset=UTF-8",
      };
    }
    return {};
  } catch (e) {
    console.log("请求头拦截错误");
    return {};
  }
};

// const getHeader = async () => {
//   try {
//     var token2 = window.token;
//     console.log("window.token", token2);
//     let token = "";
//     await new Promise((resolve, reject) => {
//       upsdk.pluginReady(() => {
//         upsdk.queryData({
//           key: "token",
//           success: function (result) {
//             token = result.value;
//             resolve();
//           },
//           fail: function (error) {
//             reject(error);
//           },
//         });
//       });
//     });
//     console.log("缓存里的.token", token);

//     return {
//       Authorization: "Bearer " + token2,
//       "Content-Type": "application/json;charset=UTF-8",
//       "tempC":"xxxxxx"
//     };
//   } catch (e) {
//     console.log("请求头拦截错误")
//     return {};
//   }
// };
// 需要添加一点是没有token就跳转到登录页面

export default function $axios(options) {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: getUrl(baseURL),
      headers: getHeader(),
    });
    // 创建一个变量用于保存 Toast 实例
    let loadingToast;
    // request 拦截器
    instance.interceptors.request.use(
      (config) => {
        loadingToast = Toast.loading({
          message: "正在加载中...",
          forbidClick: true,
          loadingType: "circular",
          duration: 0,
        });
        return config;
      },
      (error) => {
        // 请求错误时
        console.log("request:", error);
        // 1. 判断请求超时
        if (
          error.code === "ECONNABORTED" &&
          error.message.indexOf("timeout") !== -1
        ) {
          console.log("timeout请求超时");
          // return service.request(originalRequest);// 再重复请求一次
        }
        // 2. 需要重定向到错误页面
        const errorInfo = error.response;
        console.log(errorInfo);
        if (errorInfo) {
          error = errorInfo.data; // 页面那边catch的时候就能拿到详细的错误信息,看最下边的Promise.reject
          const errorStatus = errorInfo.status; // 404 403 500 ...
          router.push({
            path: `/error/${errorStatus}`,
          });
        }
        return Promise.reject(error); // 在调用的那边可以拿到(catch)你想返回的错误信息
      }
    );

    // response 响应
    instance.interceptors.response.use(
      (response) => {
        if (response.data) {
          if (loadingToast) {
            loadingToast.clear();
          }
          return response.data;
        } else {
          console.log("数据错误，请重试...");
        }
      },
      (error) => {
        if (loadingToast) {
          loadingToast.clear();
        }
        /* 判断error的status代码，并将对应的信息告知用户 */
        let text = "";
        console.log(error);
        const err = JSON.parse(JSON.stringify(error));
        console.log(err);
        if (err.message) {
          switch (error.response.status) {
            case 400:
              text = "请求错误(400)，请重新申请";
              break;
            case 403:
              text = "拒绝访问(403)";
              break;
            case 404:
              text = "请求出错(404)";
              break;
            case 405:
              text = "请求类型出错(405)";
              break;
            case 500:
              text = "服务器错误(500)，请重启软件或切换功能页！";
              break;
            default:
              text = err.message;
          }
        } else {
          text = err.message;
        }
        console.log(text);
        return Promise.reject(text);
      }
    );

    // 请求处理
    instance(options)
      .then((res) => {
        resolve(res);
        return false;
      })
      .catch((error) => {
        reject(error);
      });
  });
}
