import md5 from './md5.js';
import cfg from './config.js';
//服务端地址
const SERVER = cfg.SERVER;// 'https://mh1.gz1.kpweb.cn/api/'
const URL = cfg.URL;// 'https://mh1.gz1.kpweb.cn'
//版本

const VERISON = cfg.VERISON;
const V = cfg.V;
const DEBUG = true;
const IMGURL = cfg.IMGURL;
Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1,                 //月份 
    "d+": this.getDate(),                    //日 
    "h+": this.getHours(),                   //小时 
    "m+": this.getMinutes(),                 //分 
    "s+": this.getSeconds(),                 //秒 
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
    "S": this.getMilliseconds()             //毫秒 
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    }
  }
  return fmt;
}
String.prototype.endWith = function (endStr) {
  var d = this.length - endStr.length;
  return (d >= 0 && this.lastIndexOf(endStr) == d)
}
function DateAdd(interval, number, date) {
  switch (interval) {
    case "y": {
      date.setFullYear(date.getFullYear() + number);
      return date;
      break;
    }
    case "q": {
      date.setMonth(date.getMonth() + number * 3);
      return date;
      break;
    }
    case "m": {
      date.setMonth(date.getMonth() + number);
      return date;
      break;
    }
    case "w": {
      date.setDate(date.getDate() + number * 7);
      return date;
      break;
    }
    case "d": {
      date.setDate(date.getDate() + number);
      return date;
      break;
    }
    case "h": {
      date.setHours(date.getHours() + number);
      return date;
      break;
    }
    case "m": {
      date.setMinutes(date.getMinutes() + number);
      return date;
      break;
    }
    case "s": {
      date.setSeconds(date.getSeconds() + number);
      return date;
      break;
    }
    default: {
      date.setDate(d.getDate() + number);
      return date;
      break;
    }
  }
}
function formatTime(date) {
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var day = date.getDate()

  var hour = date.getHours()
  var minute = date.getMinutes()
  var second = date.getSeconds()


  return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

function formatNumber(n) {
  n = n.toString()
  return n[1] ? n : '0' + n
}
//错误提示并后退
function alertAndGoBack(message) {
  wx.showModal({
    title: '提示',
    showCancel: false,
    content: message,
    success: function (res) {
      wx.navigateBack({
        delta: 1
      })
    }
  })
  return false;
}
//错误提示并后退
function alert(message) {
  wx.showModal({
    title: '提示',
    showCancel: false,
    content: message,
    success: function (res) {
      return false;
    }
  })
  return false;
}
//判断是否为数字
function isNum(s) {
  if (s != null && s != "") {
    return !isNaN(s);
  }
  return false;
}
function checkImgType(ths) {
  if (!ths && ths == "") {
    return false;
  } else {
    if (!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(ths)) {
      return false;
    }
  }
  return true;
}
//去除html格式
function removeHTMLTag(str) {
  if (!str || str == "") return '';
  str = str.replace(/<\/?[^>]*>/g, ''); //去除HTML tag
  str = str.replace(/[ | ]*\n/g, '\n'); //去除行尾空白
  //str = str.replace(/\n[\s| | ]*\r/g,'\n'); //去除多余空行
  str = str.replace(/&nbsp;/ig, '');//去掉&nbsp;
  return str;
}
//将列表字符串还原成数组
function getImgList(str) {
  var imgs = [];
  if (str && str != "") {
    var tmp = str.split('|||');
    if (tmp && tmp.length > 0) {
      tmp.map(function (v, index) {
        if (v && v != "" && checkImgType(v)) {
          if (v.indexOf('http') == 0)
            imgs.push(v);
          else
            imgs.push(URL + v);
        }
      })
    }
  }
  return imgs;
}
//随机排列数组
function shuffle(arr) {
  if (!arr) {
    return null;
  }
  var i, j, temp;
  for (i = arr.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }
  return arr;
}
function checkApi(str) {
  if (!wx.canIUse(str)) {
    wx.showModal({
      title: '错误提示',
      content: '为了更好地体验小程序，请将您的微信升级到最新版。',
      success: function (res) {
        if (res.confirm) {
          console.log('用户点击确定')
        } else if (res.cancel) {
          console.log('用户点击取消')
        }
      }
    })
  }
}
function saveShuibiao(id, name, building, floor, room, callback) {
  if (id && name && building && floor && room) {
    let oldList = [];// wx.getStorageSync('wukashui');
    try {
      var value = wx.getStorageSync('wukashui')
      if (value) {
        oldList = value;
      }
    } catch (e) {
    }
    if (oldList && oldList.length > 0 && oldList.findIndex(x => x.id == id) > -1) {
      let oldIndex = oldList.findIndex(x => x.id == id);
      let item = oldList[oldIndex];
      item.name = name;
      item.building = building;
      item.floor = floor;
      item.room = room;
      oldList[oldIndex] = item;
    } else {
      let newitem = {
        id: id,
        name: name,
        building: building,
        floor: floor,
        room: room,
        isopen: false
      }
      oldList.push(newitem);
    }
    //加入缓存
    wx.setStorage({
      key: 'wukashui',
      data: oldList,
      success: function () {
        if (callback) {
          callback();
        }
      }
    })

  }
}
function getShuibiao() {
  let oldList = [];// wx.getStorageSync('wukashui');
  try {
    var value = wx.getStorageSync('wukashui')
    if (value) {
      oldList = value;
    }
  } catch (e) {
  }
  return oldList;
}

//获取随机数
function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);
      break;
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
      break;
    default:
      return 0;
      break;
  }
}
//排序
function sortObject(obj) {
  //console.log(typeof (obj) == "object")
  if (typeof (obj) == "object") {
    let newObj = {}
    Object.keys(obj).sort().map(x => {
      //console.log(x);
      newObj[x] = obj[x];
    })
    //console.log("new:", newObj);
    return newObj;
  }
}
//签名数组
function mySignObject(obj) {
  //我的签名，先加入两个参数，一个是时间戳，一个是随机字符
  //然后排序，取出所有值，拼接在一起，然后md5一次得到 signature ，然后再放到对象后面
  if (typeof (obj) == "object") {
    let timeStamp = new Date().getTime();
    let random = randomNum(100000, 999999);
    obj.timeStamp = timeStamp;
    obj.random = random;
    let newObj = sortObject(obj);
    //console.log('处理后：',newObj);
    //拼接
    let strVal = '';
    Object.keys(newObj).sort().map(x => {
      if (newObj[x]) {
        strVal += newObj[x];
      } else {
        strVal += newObj[x];
      }

    })
    //console.log('拼接字符',strVal);
    //生成签名
    let signature = md5(strVal + "comcms");
    newObj.signature = signature;
    return newObj;
    //console.log(newObj);
  }
}
//生成query验签
function createQuerySign(obj) {
  if (typeof (obj) == "object") {
    let sign = mySignObject(obj);
    if (sign) {
      let queryStr = '';
      Object.keys(sign).sort().map(x => {
        console.log(x);
        queryStr += x + "=" + sign[x] + "&";
      });
      //console.log('拼接：',queryStr);
      if (queryStr != '') {
        //清除最后一个&
        queryStr = '?' + queryStr.substr(0, queryStr.length - 1);
      }

      return queryStr;
    }
    else {
      return '';
    }
  } else {
    return '';
  }
}
function uploadimg(data, callBack) {
  var that = this,
    i = data.i ? data.i : 0,
    success = data.success ? data.success : 0,
    fail = data.fail ? data.fail : 0;
  console.log('准备上传图片', data);
  var header = {};
  var token = wx.getStorageSync('token')
  if (token) {
    header = { 'Authorization': 'Bearer ' + token };
  }
  wx.uploadFile({
    url: data.url,
    filePath: data.path[i],
    name: 'fileData',//这里根据自己的实际情况改
    formData: data.formData,
    header: header,
    success: (resp) => {
      var data = JSON.parse(resp.data);
      if (data.code == 0) {
        success++;
        let oldImagList = [];
        try {
          var value = wx.getStorageSync('uploadimg')
          if (value) {
            oldImagList = value;
          }
        } catch (e) {
        }
        oldImagList.push(data.detail.src);
        console.log('now imgs:', oldImagList);
        //写入
        wx.setStorage({
          key: 'uploadimg',
          data: oldImagList,
        })
      } else {
        fail++;
      }

      console.log(resp)
      console.log(i);
      //这里可能有BUG，失败也会执行这里,所以这里应该是后台返回过来的状态码为成功时，这里的success才+1
    },
    fail: (res) => {
      fail++;
      console.log('fail:' + i + "fail:" + fail, res);
    },
    complete: () => {
      console.log(i);
      i++;
      if (i == data.path.length) {   //当图片传完时，停止调用          
        console.log('执行完毕');
        console.log(typeof callBack);
        if (typeof callBack == "function") {
          callBack();
        }
        console.log('成功：' + success + " 失败：" + fail);
      } else {//若图片还没有传完，则继续调用函数
        console.log(i);
        data.i = i;
        data.success = success;
        data.fail = fail;
        that.uploadimg(data, callBack);//这里要把callBack 继续传递过去，否则有bug
      }

    }
  });
}
function isURL(str_url) {// 验证url
  var str = str_url;
  //判断URL地址的正则表达式为:http(s)?://([\w-]+\.)+[\w-]+(/[\w- ./?%&=]*)?
  //下面的代码中应用了转义字符"\"输出一个字符"/"
  var Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
  var objExp = new RegExp(Expression);
  if (objExp.test(str) == true) {
    return true;
  } else {
    return false;
  }
}

function getQueryStr(str, url) {
  var rs = new RegExp("(^|)" + str + "=([^&]*)(&|$)", "gi").exec(url), tmp;
  if (tmp = rs) {
    return tmp[2];
  }
  // parameter cannot be found
  return "";
}
// 16进制转字符串
function hex2str(hex) {
  var trimedStr = hex.trim();
  var rawStr = trimedStr.substr(0, 2).toLowerCase() === "0x" ? trimedStr.substr(2) : trimedStr;
  var len = rawStr.length;
  if (len % 2 !== 0) {
    alert("Illegal Format ASCII Code!");
    return "";
  }
  var curCharCode;
  var resultStr = [];
  for (var i = 0; i < len; i = i + 2) {
    curCharCode = parseInt(rawStr.substr(i, 2), 16);
    resultStr.push(String.fromCharCode(curCharCode));
  }
  return resultStr.join("");
}
//
function str2hex(str) {
  if (str === "") {
    return "";
  }
  var arr = [];
  arr.push("0x");
  for (var i = 0; i < str.length; i++) {
    arr.push(str.charCodeAt(i).toString(16));
  }
  return arr.join('');
}
//获取缓存中的热水表
function getCacheDevices() {
  let list = wx.getStorageInfoSync('cache_devices');
  if (list) {
    return list;
  }
  return [];
}
//将蓝牙热水表存入缓存
function setCacheDevices(ble) {
  if (!ble || (ble.length != 6 && ble.length != 15)) {
    return;
  }
  let list = wx.getStorageInfoSync('cache_devices');
  if (!list) {
    list = [];
  }
  if (list && list.length > 0) {
    //判断是否存在
    let index = list.findIndex(x => x == ble);
    if (index == -1) {
      list.push(ble)
    } else {
      list.split(index, 1);
      list.push(ble)
    }
  } else {
    list.push(ble)
  }
  wx.setStorageSync('cache_devices', list)

}

export default {
  formatTime: formatTime,
  SERVER: SERVER,
  URL: URL,
  VSERISON: VERISON,
  V: V,
  alert: alert,
  alertAndGoBack: alertAndGoBack,
  removeHTMLTag: removeHTMLTag,
  checkImgType: checkImgType,
  getImgList: getImgList,
  isNum: isNum,
  APPNAME: cfg.APPNAME,
  DEC: cfg.APPDESC,
  shuffle: shuffle,
  checkApi: checkApi,
  md5: md5,
  DEBUG: DEBUG,
  saveShuibiao: saveShuibiao,
  getShuibiao: getShuibiao,
  randomNum: randomNum,
  sortObject: sortObject,
  mySignObject: mySignObject,
  DateAdd: DateAdd,
  uploadimg: uploadimg,
  isURL: isURL,
  WSURL: cfg.WSURL,
  getQueryStr: getQueryStr,
  hex2str: hex2str,
  str2hex: str2hex,
  IMGURL: IMGURL,
  NOTIFYTPLS: cfg.NOTIFYTPLS,
  getCacheDevices: getCacheDevices,
  setCacheDevices: setCacheDevices
}
